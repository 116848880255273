<template>
    <div class="upgrade">
        <Header :type="headerType"/> 
        <div class="main">
            <h1>Upgrade my plan</h1>
            <h3>Feel the room is not enough for you to create freely?<br>You can consider upgrading your plan to make your exhibition be more perfect !</h3>
            <div class="container">
                <div class="cardWrap">
                    <h2>BASIC</h2>
                    <p>US $10 / month</p>
                    <h2>US $0  FREE </h2>
                    <ul>
                        <li><img src="@/assets/img/icon/check.svg" alt=""><p>1 exhibition</p></li>
                        <li><img src="@/assets/img/icon/check.svg" alt=""><p>1 exhibition room</p></li>
                    </ul>
                </div>
                <div class="cardWrap">
                    <div class="recommend">
                        Most recommend
                    </div>
                    <h2>PREMIUM</h2>
                    <p>US $10 / month</p>
                    <h2>US $15 / month</h2>
                    <ul>
                        <li><img src="@/assets/img/icon/check_a.svg" alt=""><p>1 exhibition</p></li>
                        <li><img src="@/assets/img/icon/check_a.svg" alt=""><p>3 exhibition room</p></li>
                        <li><img src="@/assets/img/icon/check_a.svg" alt=""><p>Prioritize making your exhibition more <span>Visible</span></p></li>
                    </ul>
                </div>
                <div class="cardWrap">
                    <h2>CUSTOM</h2>
                    <p>Your exclusive plan</p>
                    <h2>CUSTOM PRICING</h2>
                    <ul>
                        <li><img src="@/assets/img/icon/check.svg" alt=""><p>More exhibitions</p></li>
                        <li><img src="@/assets/img/icon/check.svg" alt=""><p>More exhibition rooms</p></li>
                        <li><img src="@/assets/img/icon/check.svg" alt=""><p>Special exposure and  ranking</p></li>
                    </ul>
                </div>
            </div>
            <div class="contactus">
                <h2>Contact us to upgrade your plan</h2>
                <ul>
                    <li><div class="iconWrap"><img src="@/assets/img/icon/mailIcon.svg" alt=""></div>service@izexpo.com</li>
                    <li><div class="iconWrap"><img src="@/assets/img/icon/phoneIcon.svg" alt=""></div>04-26997997</li>
                    <li><div class="iconWrap"><img src="@/assets/img/icon/locationIcon.svg" alt=""></div>No. 403, Sec. 2, Shatian Rd., Dadu Dist., Taichung City 432001</li>
                    <!-- <li><div class="iconWrap"><img src="@/assets/img/icon/linkIcon.svg" alt=""></div>https://bkhole.com/</li> -->
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header'

export default {
    name:"upgrade",
    data(){
        return{
            headerType:'normal_u',
        }
    }, 
    components:{
        Header
    },
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .upgrade{
        min-width: 900px;
        .main{
            padding-top: 4%;
            h1,h2,h3,h4,p{
                margin:0;                
            }
            h1{
                font-size: 40px;
                margin-bottom: 3%;
                font-weight: 500;
            }
            h3{
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
                margin-bottom: 10%;
            }
            .container{
                display: flex;
                justify-content: center;
                margin-bottom: 6%;
                .cardWrap{
                    // width: 20%;
                    width: 265px;
                    margin: 0 2%;
                    padding:2% 0 3% 0;
                    border-radius: 9px;
                    background-color: #fff;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
                    position:relative;
                    .recommend{
                        position: absolute;
                        left:50%;
                        transform:translateX(-50%);
                        width:100%;
                        color:#fff;
                        font-size: 16px;
                        background-color: $purple;
                        border-top-right-radius: 9px;
                        border-top-left-radius: 9px;
                        top: -10%;
                        line-height: 2.5;
                    }
                    h2{
                        font-size: 25px;
                        margin-bottom: 3%;
                        &:nth-of-type(2){
                            color:$purple;
                            margin-bottom: 9%;
                        }
                    }
                    p{
                        font-size: 12px;
                        margin-bottom: 3%;
                        color:#757575;
                    }
                    ul{
                        padding: 0 14%;
                        li{
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 10%;
                            p{
                               font-size: 14px; 
                               color:#282828;
                               text-align: left;
                               margin-bottom:0;
                               line-height: 1.5;
                               span{
                                   color:$purple;
                                   font-weight: 600;
                               }
                            }
                            img{
                                align-self: flex-start;
                                margin-right: 9%;
                                margin-top: 3px;
                                // width: 11%;
                            }
                        }
                    }
                }
            }
            .contactus{
                // margin-bottom: 10%;
                width: 426px;
                margin: 0 auto 10%;
            }
            .contactus h2{
                margin-bottom: 8%;
            }
            .contactus ul{
                text-align: left;
                // width: 35%;
                margin: 10px auto;
                
                li{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    // font-size: 25px;
                    margin-bottom: 18px;
                    .iconWrap{
                        text-align: center;
                        width: 18px;
                        margin-right: 15px;
                    }
                    img{
                        width:18px;
                        display: inline-block;
                        vertical-align: middle
                    }
                    &:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4){
                            img{
                                width:15px;
                            }
                    }
                }
            }
        }
    }
</style>